import { Component } from "react";

import "./index.scss";
import Header from "./header";

export default class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "home",
      data: {},
      impress: false,
    };

    this.changeTab = this.changeTab.bind(this);
  }

  componentDidMount() {
    fetch("./settings.json")
      .then((res) => res.json())
      .then((data) => {
        this.setState({ data: data });
      });

    window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  handleScroll() {
    if (window.scrollY >= document.getElementById("kontakt").offsetTop - 95) {
      this.setState({ activeTab: "kontakt" });
    } else if (window.scrollY >= document.getElementById("leistungen").offsetTop - 95) {
      this.setState({ activeTab: "leistungen" });
    } else {
      this.setState({ activeTab: "home" });
    }
  }

  changeTab(e) {
    this.setState({ activeTab: e.target.name });
  }

  categoryLayout() {
    let categories = [];

    if (Object.keys(this.state.data).length !== 0) {
      this.state.data["leistungen"]["categories"].forEach((category, index) => {
        categories.push(
          <div className={`category ${category.color} ${category.orientation}`}>
            <div className="content">
              <h2 className="cursive">{category.title}</h2>
              <p>{category.subtitle}</p>
            </div>
            <ul className={`list tab`}>
              {category.list.map((item) => (
                <li className="item">
                  <p>{item}</p>
                </li>
              ))}
            </ul>
            <div className={`img tab`} style={{ backgroundImage: `url(${`./images/${category.img}`})` }}></div>
          </div>
        );
      });
    }

    return categories;
  }

  render() {
    return (
      <div className="index">
        <Header activeTab={this.state.activeTab} changeTab={this.changeTab} />
        <section id="home" style={{ backgroundImage: `url(${"./images/background.jpg"})` }}>
          <div className="overlay"></div>
          <div className="content">
            <h1 className="cursive">
              <span>Garten & Landschaftsbau</span>
            </h1>
            <br />
            <h1>
              <span>Ihr Garten,</span>
              <span>unser Projekt!</span>
            </h1>
            <p>
              Wir machen Ihren Garten zu einer Wohlfühloase, unterstützen Sie bei schwierigen Entscheidungen und gestalten mit
              Ihnen gemeinsam einen Gartentraum!
            </p>
            <div className="buttons">
              <button id="more">
                <a href="#leistungen"></a>
                Weiter Lesen
              </button>
              <button id="contact">
                <a href="#kontakt"></a>
                Kontakt
              </button>
            </div>
          </div>

          {/* <img src={"./images/Gaertner-weiß.png"} /> */}
        </section>
        {/* <section id="banner">
          <img src={"./images/banner2.jpeg"} alt="" loading="lazy" />
          <div className="text">
            <h1>AS Garten</h1>
            <h2>Garten- und Landschaftsbau</h2>
          </div>
        </section>
        <section id="iconBanner">
          <div className="icons">
            <img src={"./icons/wachsen.png"} alt="" loading="lazy" />
            <img src={"./icons/traktor.png"} alt="" loading="lazy" />
            <img src={"./icons/baum.png"} alt="" loading="lazy" />
          </div>
        </section> */}
        <section id="leistungen">{this.categoryLayout()}</section>
        <section id="banner2" style={{ backgroundImage: `url(${"./images/footer.jpeg"})` }}>
          <div className="overlay"></div>
          <div className="print">
            <img src={"./images/LogoFull.png"} alt="" />
            <h1>{Object.keys(this.state.data).length > 0 ? this.state.data.banner2.title : ""}</h1>
          </div>
        </section>
        <section id="kontakt">
          <div className="bar left">
            <h3> Navigation</h3>
            <ul>
              <li>
                <a href="#home">Home</a>
              </li>
              <li>
                <a href="#leistungen">Leistungen</a>
              </li>
              <li>
                <a href="#kontakt">Kontakt</a>
              </li>
              <li
                onClick={() => {
                  this.setState({ impress: true });
                }}
              >
                <a>Impressum / Datenschutz</a>
              </li>
            </ul>
          </div>
          <div className="bar right">
            <h3>Kontakt</h3>
            <ul>
              <li>
                <a
                  href="https://www.google.de/maps/place/Gereonswall+101,+50670+K%C3%B6ln/@50.9449565,6.9426853,17z/data=!3m1!4b1!4m5!3m4!1s0x47bf2509d045baa1:0xb2582b0b3c148b05!8m2!3d50.9449565!4d6.944874"
                  target="_blank"
                >
                  {Object.keys(this.state.data).length > 0 ? this.state.data.footer.adresse : ""}
                </a>
              </li>
              <li>
                <a href={`tel://${Object.keys(this.state.data).length > 0 ? this.state.data.footer.telefon : ""}`}>
                  {Object.keys(this.state.data).length > 0 ? this.state.data.footer.telefon : ""}
                </a>
              </li>
              <li>
                <a href={`mailto://${Object.keys(this.state.data).length > 0 ? this.state.data.footer.email : ""}`}>
                  {Object.keys(this.state.data).length > 0 ? this.state.data.footer.email : ""}
                </a>
              </li>
            </ul>
          </div>
        </section>
        <div className={`impress ${this.state.impress ? "active" : ""}`} onClick={() => this.setState({ impress: false })}>
          <h1>Impressum</h1>
        </div>
      </div>
    );
  }
}
