import { Component } from "react";

export default class Header extends Component {
  render() {
    return (
      <div className="header">
        <div className="logo" style={{ backgroundImage: `url(${"./images/Logo.png"})` }} />
        <div className="header-items">
          <a
            href="#home"
            className={`nav-item ${this.props.activeTab === "home" ? "active" : ""}`}
            name="home"
            onClick={this.props.changeTab}
          >
            Home
          </a>
          <a
            href="#leistungen"
            className={`nav-item ${this.props.activeTab === "leistungen" ? "active" : ""}`}
            name="leistungen"
            onClick={this.props.changeTab}
          >
            Leistungen
          </a>
          <a
            href="#kontakt"
            className={`nav-item ${this.props.activeTab === "kontakt" ? "active" : ""}`}
            name="kontakt"
            onClick={this.props.changeTab}
          >
            Kontakt
          </a>
          {/* <a href="#home" className={`nav-item ${this.props.activeTab === "home" ? "active" : ""}`}>
            Gartenbau
          </a> */}
        </div>
      </div>
    );
  }
}
